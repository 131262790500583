import { stringEnvVar, unionEnvVar } from "shared/helper/utils";

export const VERSION = stringEnvVar(process.env["NEXT_PUBLIC_VERSION"]);

export const APP_ORIGIN = stringEnvVar(process.env["NEXT_PUBLIC_APP_ORIGIN"]);

export const GTM_ID = stringEnvVar(process.env["NEXT_PUBLIC_GTM_ID"]);

export const MODE = unionEnvVar(process.env["NEXT_PUBLIC_MODE"], [
  "dev",
  "pro",
  "emulator",
] as const);

export const FIREBASE_OPTIONS = JSON.parse(
  process.env["NEXT_PUBLIC_FIREBASE_OPTIONS"] ?? "{}"
);
