var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole } from "@sentry/integrations";

import { MODE } from "./src/envs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://af2e69381746412aa31ea12a25ea5576@o1093838.ingest.sentry.io/6113227",
  tracesSampleRate: 1.0,
  environment: MODE,
  integrations: [
    new CaptureConsole({
      levels: ["error"],
    }),
  ],
});
